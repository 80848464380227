import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    data(){
        return{
            form:{
                card: '',
                full_name: '',
                account_id: 'UA',
                password: '',
                card_limit: 0,
            },
            validTrue:false,
        }
    },
    computed:{
      ...mapGetters({
          card:'rate/card'
      })
    },
    created() {
        if(this.card){
            this.form = {
                card: this.card.card,
                full_name: this.card.full_name,
                account_id: this.card.account_id,
                password: this.card.password,
                card_limit: this.card.card_limit,
            }
        }
    },
    methods:{
        createCard(){
            if(this.validForm()){
                if(this.card){
                    this.changeCard({
                        id: this.card.id,
                        data:{
                            card: this.form.card.split('-').join(''),
                            full_name: this.form.full_name,
                            account_id: this.form.account_id,
                            password: this.form.password,
                            card_limit: this.form.card_limit,
                            status:1,
                        }
                    }).then(res=>{
                        this.getSaveResult(res)
                    })
                }else{
                    this.addCard({
                        card: this.form.card.split('-').join(''),
                        full_name: this.form.full_name,
                        account_id: this.form.account_id,
                        password: this.form.password,
                        card_limit: this.form.card_limit,
                        status:1,
                    }).then(res=>{
                        this.getSaveResult(res)
                    })
                }
            }else{
                this.$toasted.error('Заповніть всі поля правильно!', {
                    duration: 3000,
                    position: "top-center"
                })
            }
        },
        getSaveResult(res){
            if(res.result){
                this.getRate();
                this.$toasted.success('Карта успішно додана!', {
                    duration: 3000,
                })
                this.clearForm();
            }else{
                Object.keys(res.data.errors).map(item => {
                    console.log(item )
                    this.$toasted.error(res.data.errors[item][0], {
                        duration: 3000,
                        position: "top-center"
                    })
                })
            }
        },
        validForm(){
            let valid = true;
            Object.keys(this.form).map(item =>{
                if(typeof this.form[item] === 'string' && !this.form[item].length) {
                    valid = false;
                }
            })
            if(this.form.card.length !== 19){
                valid = true;
            }
            if(this.form.account_id.length !== 29){
                this.$toasted.error('Account id повино містити 29 символів.', {
                    duration: 3000
                })
                valid = false;
            }
            return valid
        },
        clearForm(){
            this.form = {
                card: '________________',
                fullName: '',
                account_id: 'UA',
                password: '',
                card_limit: '',
                status: 1,
            }
            this.closePopup();
        },
        closePopup(){
            this.$emit('closePopup')
        },
        ...mapActions({
            changeCard:'rate/changeCard',
            addCard:'rate/addCard',
            getRate:'rate/getRate',
        }),
        ...mapMutations({
            getCard:'rate/getCard'
        })
    },
    beforeDestroy() {
        this.getCard(null);
    }
}
